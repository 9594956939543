import React, { memo, useMemo } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { DLClickEventHandlerProps } from 'tsconfig/types';
import { RenderDynamicComponent } from './RenderDynamicComponent/RenderDynamicComponent';

export type CmsContentSectionProps = {
  contentJsonList: any;
  category?: string;
  dlClickEventHandler?: DLClickEventHandlerProps;
};

type RenderDynamicComponentsProps = {
  componentDataList: any[];
  dlClickEventHandler?: DLClickEventHandlerProps;
};

const RenderDynamicComponents = (props: RenderDynamicComponentsProps) => {
  const { componentDataList, dlClickEventHandler } = props;
  return (
    <>
      {componentDataList?.map(item => (
        <RenderDynamicComponent contentItem={item} dlClickEventHandler={dlClickEventHandler} key={item?.sys?.id} />
      ))}
    </>
  );
};

export const MemoizedRenderDynamicComponents = memo(RenderDynamicComponents);

export const CmsContentSection = memo((props: CmsContentSectionProps) => {
  const { contentJsonList, dlClickEventHandler } = props;
  if (!contentJsonList) {
    return null;
  }
  const theme = useTheme();
  const widgetTheme = theme.widgets.CmsContentSection || {};
  const Wrapper = useMemo(
    () => styled.section`
      ${widgetTheme.wrapper}
    `,
    [],
  );

  return (
    <Wrapper data-test="dynamic-components" className="dynamicComponents">
      <MemoizedRenderDynamicComponents componentDataList={contentJsonList} dlClickEventHandler={dlClickEventHandler} />
    </Wrapper>
  );
});
