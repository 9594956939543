import React, { useEffect, useState, useMemo } from 'react';
import { css, useTheme } from '@emotion/react';
import Grid from '../../grid/Grid';
import { BannerProps } from 'tsconfig/types';
import styled from '@emotion/styled';
import { CmsImage } from '../CmsImage/CmsImage';
import { CmsLink } from '../CmsLink/CmsLink';
import { inspectorMode, useScreenLayout } from 'common-ui';
import { fontCorrectionInRichText } from '../../../utils/fontCorrectionInRichText';
import BannerWithContentAndCTA from '../../BannerComponents/BannerComponentWithContentAndCta';
import { BannerWithButton } from '../../BannerComponents/BannerComponentWithButton';
import {
  getClassNameBasedOnVariant,
  getContentWidth,
  getDataTestId,
  getTintedBackground,
  hasTintedBackground,
  getGradient,
} from '../../BannerComponents/BannerUtility';
import getPixalImageDataUrl from '../../../utils/getPixalImageDataUrl';
import SkeletonBanner from '../../Skeleton/Banner/Banner';
import { navigateToBannerLink, stopBannerContentEventPropagation } from './CmsBannerUtility';

type CmsBannerType = React.MemoExoticComponent<({ contentItem }: BannerProps) => JSX.Element> & {
  BannerWithContentAndCTA: typeof BannerWithContentAndCTA;
};

export const CmsBanner: CmsBannerType = Object.assign(
  React.memo(({ contentItem }: BannerProps) => {
    const {
      variant = '',
      CTALink = [],
      contentBackgroundColour = '',
      bannerContent = '',
      alignment = '',
      image,
      backgroundImage,
      contentWidth = '',
      minimumBannerHeight,
      bannerLink,
    } = contentItem.fields || {};

    const [isLoading, setIsLoading] = useState(true);
    const theme = useTheme();
    const mediaSm = theme.breakpoints.sm ? theme.breakpoints.xs.replace('@media', '') : '';

    const { screen, isMobile } = useScreenLayout();

    // Optimize image URL determination
    const backgroundImageUrl = useMemo(() => {
      const smallDeviceUrl = backgroundImage?.fields?.smallDeviceImage?.fields?.file?.url;
      const largeDeviceUrl = backgroundImage?.fields?.largeDeviceImage?.fields?.file?.url;
      return screen && isMobile ? smallDeviceUrl || largeDeviceUrl : largeDeviceUrl;
    }, [screen, isMobile, backgroundImage]);

    const isCenterTintedBackground = variant === 'Full Background - Tinted Centre';
    const bannerContentWidth = getContentWidth(contentWidth);
    const tintedBackground = hasTintedBackground(variant);

    useEffect(() => {
      if (bannerContent) setIsLoading(false);
    }, [bannerContent]);

    // Use effect to preload images
    useEffect(() => {
      const preloadImage = (url: string | undefined) => {
        if (!url) return;
        const link = document.createElement('link');
        link.rel = 'preload';
        link.href = url;
        link.as = 'image';
        document.head.appendChild(link);
      };

      const smallImage = image?.fields?.smallDeviceImage?.fields?.file?.url;
      const largeImage = image?.fields?.largeDeviceImage?.fields?.file?.url;

      preloadImage(screen && isMobile ? smallImage : largeImage);
      preloadImage(backgroundImageUrl);
    }, [image, backgroundImageUrl, screen, isMobile]);

    const wrapper = css`
      ${theme.widgets.CmsBanner?.default};
      margin-bottom: 10px;
      container: banner / inline-size;
      .banner {
        padding: 0;
        ${minimumBannerHeight && `min-height: ${minimumBannerHeight}px`};
        ${bannerLink && `cursor: pointer`};
        ${getTintedBackground(variant, backgroundImageUrl, theme.breakpoints.sm)}
        .banner-image-wrapper {
          ${tintedBackground
            ? `@media (max-width: 768px) {
                padding: 20px 0px 20px 0px;
                display: flex;
                justify-content: center;
                max-height: 400px;
              }`
            : `background: url(${backgroundImageUrl});
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;`}
          min-height: 300px;
          min-width: 100%;
          position: relative;
          ${theme.breakpoints.xs} {
            min-width: auto;
          }
          .banner-imgcomp {
            position: relative;
            z-index: 2;
          }
          .banner-bg-img {
            position: absolute;
            z-index: -1;
            height: 100%;
            width: 100%;
          }
        }
        .banner-content-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto;
          ${tintedBackground
            ? ''
            : `background: ${
                contentBackgroundColour
                  ? (theme.colors.bgColors as unknown as Record<string, string>)[contentBackgroundColour]
                  : 'initial'
              };
            background-color: ${contentBackgroundColour};`}
          text-align: ${alignment.toLowerCase()};
          .banner-info {
            .banner-button {
              justify-content: ${alignment.toLowerCase()};
            }
          }
        }
      }

      @container banner (width < ${parseInt(theme.breakpoints.md.split(' ')[2]) / 2.13}px) {
        .banner {
          background:
            ${getGradient(variant.replace('Full Background - Tinted ', ''), true)},
            url(${backgroundImageUrl}) lightgray 50% / cover no-repeat;

          .banner-content-wrapper,
          .banner-image-wrapper {
            width: 100%;
          }
          .banner-info {
            padding: 16px;
          }
          .banner-content {
            padding: 24px 16px;
            margin-bottom: 24px;
            border-radius: 8px;
            h2 {
              font-size: 30px;
              text-wrap: pretty;
            }
          }
        }
      }
    `;

    const Section = styled.section`
      ${theme.widgets.CmsBanner?.wrapper}
    `;

    {
      isLoading && <SkeletonBanner />;
    }

    if (variant === 'Full width background banner with Calls to Action') {
      return <BannerWithButton contentItem={contentItem} />;
    }

    return (
      <div
        css={wrapper}
        data-test="banner"
        data-testid={`banner-${getDataTestId(variant)}`}
        onClick={() => navigateToBannerLink(bannerLink)}
        onKeyDown={e => e.key === 'Enter' && navigateToBannerLink(bannerLink)}
        role="button"
        tabIndex={0}
      >
        <Grid justify="start" className="banner">
          {!isCenterTintedBackground && (
            <Grid.Col
              md={contentWidth ? 12 - bannerContentWidth : 6}
              xs={12}
              className={`banner-image-wrapper ${getClassNameBasedOnVariant(variant, false)}`}
            >
              {image && (
                <div className="banner-imgcomp">
                  <CmsImage contentItem={image} />
                </div>
              )}
              {!hasTintedBackground(variant) && backgroundImage && (
                <div className="banner-bg-img">
                  <CmsImage
                    contentItem={backgroundImage}
                    sizes={`${mediaSm}: ${((12 - bannerContentWidth) / 12) * 100}vw, 100vh`}
                    fill
                    isNextImg
                    blurDataURL={getPixalImageDataUrl(contentBackgroundColour)}
                    placeholder="blur"
                  />
                </div>
              )}
            </Grid.Col>
          )}

          <Grid.Col
            md={bannerContentWidth}
            xs={12}
            className={`banner-content-wrapper ${getClassNameBasedOnVariant(variant, true)}`}
          >
            <div data-testid={`banner-info-${alignment.toLowerCase()}`} className="banner-info">
              <Section
                data-test="contentful-textbox"
                data-testid={`banner-content-${bannerContentWidth}`}
                className="banner-content"
              >
                {bannerContent && /<[a-z][\s\S]*>/i.test(bannerContent) && (
                  <div
                    onClick={stopBannerContentEventPropagation}
                    onKeyDown={stopBannerContentEventPropagation}
                    dangerouslySetInnerHTML={{ __html: fontCorrectionInRichText(bannerContent, theme.fonts) }}
                    {...inspectorMode(contentItem?.sys?.id, 'bannerContent')}
                    role="textbox"
                    tabIndex={0}
                  />
                )}
              </Section>
              {CTALink.length > 0 && (
                <div className="banner-button">
                  {CTALink.map((value: any, index: number) => (
                    <CmsLink key={index} contentItem={value} />
                  ))}
                </div>
              )}
            </div>
          </Grid.Col>
        </Grid>
      </div>
    );
  }),
  { BannerWithContentAndCTA },
);

CmsBanner.BannerWithContentAndCTA = BannerWithContentAndCTA;
