import {
  CartButtonWithQuantitySelector,
  Link,
  globals,
  ProductImage,
  smallProductImageUrl,
  Rating,
  Icon,
} from 'common-ui';

import { productLinkBuilder, formatPriceToTwoDecimals } from 'common-next/src/components/common-functions';
import { ProductPropsTransformed } from 'tsconfig/types';
import { useDisclosure } from '@mantine/hooks';
import { css, useTheme } from '@emotion/react';
import Zoom from './Zoom';

interface ItemCardProps {
  product: any;
  showRating?: boolean;
  showImageZoom?: boolean;
  hideBackgroundColour?: boolean;
  showPrice?: boolean;
  showAddtoCart?: boolean;
}

const productData = (item: ProductPropsTransformed) => {
  return {
    productName: item.productName ?? '',
    vintage: item.vintage ?? '',
    itemCode: item.itemCode ?? '',
    giftFlag: item.giftFlag ?? false,
    avgRating: item.avgRating,
    skus: item.skus,
    packaging: item.packaging ?? '',
    productType: item.productType ?? '',
    productWebType: item.productWebType ?? '',
    largeImage: item.largeImage ?? '',
    price: item?.skus?.[0]?.vppPrice ? item?.skus?.[0]?.vppPrice : item?.skus?.[0]?.salePrice,
    buyersRRP: item?.skus?.[0]?.buyersRRP,
  };
};

/**
 * Renders a card component for displaying an item.
 *
 * @example
 * ```tsx
 * <ItemCard product={productData} showRating={true} showImageZoom={true} hideBackgroundColour={false} />
 * ```
 *
 * @param {any} product - The product data for the item.
 * @param {boolean} [showRating=false] - Whether to show the rating for the item.
 * @param {boolean} [showImageZoom=false] - Whether to show the image zoom functionality for the item.
 * @param {boolean} [hideBackgroundColour=false] - Whether to hide the background color of the item card.
 * @param {boolean} [showPrice=true] - Whether to hide the Price for the item.
 * @param {boolean} [showAddtoCart=true] - Whether to hide the Add to Cart button of the item card.
 * @returns {JSX.Element} The rendered ItemCard component.
 */
export function ItemCard(props: ItemCardProps) {
  const {
    product,
    showRating = false,
    showImageZoom = false,
    hideBackgroundColour = false,
    showPrice = true,
    showAddtoCart = true,
  } = props;
  const item = productData(product);
  const [opened, { open, close }] = useDisclosure(false);

  const theme = useTheme();

  const wrapper = css`
    ${theme.widgets.Product?.ItemCard}
  `;

  const handleOnClose = () => {
    close();
  };

  return (
    <div css={wrapper}>
      {showImageZoom && (
        <span
          data-testid="zoom-container"
          className="zoom"
          onClick={() => open()}
          onKeyDown={() => open()}
          tabIndex={0}
          role="button"
        >
          <Icon kind="zoom" size="xs" />
        </span>
      )}

      <div className="card-container">
        <div
          className={`${hideBackgroundColour ? 'product-image-container no-background' : 'product-image-container'}`}
        >
          <div className="product-image">
            <Link href={productLinkBuilder(item.productName, item.vintage, item.itemCode)}>
              <ProductImage
                src={smallProductImageUrl(item.itemCode)}
                productType={item?.productType}
                alt={item.productName}
                fill={true}
              />
            </Link>
          </div>
        </div>
        <div className="info-container">
          <div className="product-name">
            <Link href={productLinkBuilder(item.productName, item.vintage, item.itemCode)}>
              {item.productName} {item.vintage}
            </Link>
          </div>

          {showRating && (
            <div data-testid="rating-container" className="rating-container">
              <Rating rating={item.avgRating} />
            </div>
          )}
          {showPrice && (
            <div className="price">
              <span className="sale-price">
                {globals.currency}
                {formatPriceToTwoDecimals(item.price)}
              </span>{' '}
              {item.buyersRRP && item.buyersRRP > item.price && (
                <span className="buyers-price">
                  {globals.currency}
                  {formatPriceToTwoDecimals(item.buyersRRP)}
                </span>
              )}
            </div>
          )}
        </div>
        {showAddtoCart && <CartButtonWithQuantitySelector itemCode={item.itemCode} showQtySelector={false} />}
        <Zoom product={item} opened={opened} handleOnClose={handleOnClose} />
      </div>
    </div>
  );
}
