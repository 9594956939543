// Wrapper for the legacy ATG API
// This API is in the progress of being migrated to the new AWS API
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { logApiRequest } from '../utils/logging';
import { UpdatingWineplanSubscription, WineplanSubscriptionListResponse } from 'tsconfig/types';

type AtgLegacyApiConfig = {
  urlPrefix: string;
  extraHeaders?: Record<string, string>;
  enableLogging?: boolean;
};

export const initAtgLegacyApi = (config: AtgLegacyApiConfig) => {
  async function asyncRequest(requestType: string, url: string, data = {}) {
    let requestConfig: AxiosRequestConfig = {},
      response: AxiosResponse | undefined,
      incomingRequestTime: Date | undefined,
      outgoingResponseTime: Date | undefined;

    try {
      incomingRequestTime = new Date();
      requestConfig = {
        method: requestType,
        url: (config.urlPrefix || '') + url,
        headers: {
          ...config.extraHeaders,
        },
      };
      if (data) {
        requestConfig.data = data;
      }
      response = await axios.request(requestConfig);
      return response ? response.data : {};
    } catch (e: any) {
      response = e.response;
      //rethrow error for caller to handle
      throw e;
    } finally {
      outgoingResponseTime = new Date();
      if (config.enableLogging) {
        //Note: may be better to throw/pass back this info so caller can log (+ add meaningful message)?
        logApiRequest(requestConfig, response, incomingRequestTime, outgoingResponseTime, 'AtgLegacyApi');
      }
    }
  }

  return {
    Cart: {
      batch: async (data: any) => await asyncRequest('POST', `/cart`, data),
      multi: async (data: any) => await asyncRequest('POST', `/cart/itemcode`, data),
      get: async () => await asyncRequest('GET', `/cart/list`),
      add: async (productSku: string, quantity: string) =>
        await asyncRequest('PUT', `/cart/itemcode/${productSku}/${quantity}`),
      getItems: async () => await asyncRequest('GET', `/cart/list?validate=true`),
      remove: async (data: any) => await asyncRequest('DELETE', `/cart/commerceitemidentifier/${data.itemcode}`),
      getCartOffer: async () => await asyncRequest('GET', `/offer/cart`),
    },
    Promotions: {
      getProductPromotions: async (itemCode: string) =>
        await asyncRequest('GET', `/promotions/bulk-promotions/${itemCode}`),
    },
    Product: {
      getOffers: async (itemCode: string) => await asyncRequest('GET', `/offer/product/${itemCode}`),
      getRecentlyViewed: async () => await asyncRequest('GET', `/user/recentlybrowsed/list`),
      addItemToRecentlyViewed: async (itemcode: string) =>
        await asyncRequest('PUT', `/user/recentlybrowsed/${itemcode}`),
    },
    User: {
      get: async () => await asyncRequest('GET', `/user/details`),
      permission: async () => await asyncRequest('GET', `/user/permissions/MARKETING`),
      softLogout: async () => await asyncRequest('GET', '/jsp/templates/page_elements/common/soft_logout.jsp'),
      hardLogout: async () => await asyncRequest('GET', '/jsp/templates/page_elements/common/hard_logout.jsp'),
      // This API is only to set user location in US Brands
      userSetLocation: async (data: { stateCode: string }) =>
        await asyncRequest('POST', `/user/profile/locationContext`, data),
      getFavourites: async () => await asyncRequest('GET', `/user/favourites/list`),
      getRecentlyBrowsed: async () => await asyncRequest('GET', `/user/recentlybrowsed/list`),
    },
    WinePlan: {
      getUpcomingCaseDetails: async (subId: string) =>
        await asyncRequest('GET', `/user/subscriptions/wineplan/${subId}/upcomingcases`),
      getProductCaseDetails: async (itemCode: string) => await asyncRequest('GET', `/product/case/${itemCode}`),
      getProductItemDetails: async (itemCode: string) => await asyncRequest('GET', `/product/item/${itemCode}`),
      getSupportedFrequencies: async (wpSubscriptionid: string): Promise<WineplanSubscriptionListResponse> =>
        await asyncRequest('GET', `/user/subscriptions/wineplan/${wpSubscriptionid}/list`),
      putSupportedFrequencies: async (
        wpSubscriptionid: string,
        data: { frequency?: number; caseType?: string; subscriptionOrderId?: string },
      ): Promise<UpdatingWineplanSubscription> =>
        await asyncRequest('PUT', `/user/subscriptions/wineplan/${wpSubscriptionid}`, data),
    },
  };
};
