import { css, useTheme } from '@emotion/react';
import { Modal } from '@mantine/core';
import { largeProductImageUrl } from '../../utils/productImage';
import { ProductPropsTransformed } from 'tsconfig/types';
import { ProductImage } from '../ProductImage/ProductImage';

interface ZoomProps {
  product: ProductPropsTransformed;
  opened: boolean;
  fullScreen?: boolean;
  handleOnClose: () => void;
}

const Zoom = (props: ZoomProps) => {
  const { product, opened, handleOnClose, fullScreen = false } = props;

  const theme = useTheme();

  const wrapper = css`
    ${theme.widgets.Product?.ItemCard}
  `;

  return (
    <Modal
      opened={opened}
      css={wrapper}
      onClose={() => handleOnClose()}
      fullScreen={fullScreen}
      transitionProps={{ transition: 'fade', duration: 200 }}
    >
      <div className="zoom-container">
        <ProductImage
          src={largeProductImageUrl(product.itemCode)}
          productType={product?.productType}
          alt={product.productName}
          fill={true}
        />
      </div>
    </Modal>
  );
};

export default Zoom;
