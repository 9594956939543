import Image from 'next/image';
import { Tabs } from '@mantine/core';
import { globals, useDefaultImageHandling } from 'common-ui';

export const CaseTab = (props: any) => {
  const { product, count, quantity } = props;

  const { imageSrc, handleImageError } = useDefaultImageHandling(
    product?.smallImage,
    `/next/images/default_images/bottle.png`,
  );

  return (
    <Tabs.Tab
      value={`prod-item-${count + 1}`}
      icon={<Image src={imageSrc} alt={product.name} width={40} height={150} onError={handleImageError} />}
    >
      x{quantity}
    </Tabs.Tab>
  );
};
