import { DLClickEventProps, DLProductEventProps, ProductProps } from 'tsconfig/types';
import { adobeDataLayer } from './AdobeDataLayer';
import { adobeProductMapData } from './adobeProductMapData';

export const handleDLProductClickEvent = (
  clickType: string,
  productData: ProductProps,
  quantity: number,
  page?: string,
) => {
  const productDetailInfo = [adobeProductMapData(productData, quantity)];
  const itemCode = productData?.skus?.[0]?.itemCode;
  const price = productData?.skus?.[0]?.salePrice;
  const pageType = adobeDataLayer.getPageType();

  // object for cart page
  const addToCartClick: DLClickEventProps = {
    clickAction: 'update cart',
    clickDescription: `${pageType}:update cart`,
    clickLocation: `${pageType}`,
    clickName: 'update cart',
    clickText: 'add',
    errorMessage: '',
  };

  // object for PDP pages
  const productAddToCartClick = {
    clickAction: 'add to cart',
    clickDescription: `${pageType}:add to cart`,
    clickLocation: `${pageType}`,
    clickName: 'add to cart',
    clickText: 'Add to Basket',
    errorMessage: '',
  };

  // object for PDP pages
  const clickInitiateCart: DLClickEventProps = {
    clickAction: 'add to empty cart',
    clickDescription: `${pageType}:add to empty cart`,
    clickLocation: `${pageType}`,
    clickName: 'add to empty cart',
    clickText: 'Add to Basket',
    errorMessage: '',
  };

  // object for cart page
  const clickRemoveFromCart: DLClickEventProps = {
    clickAction: 'update cart',
    clickDescription: `${pageType}:update cart`,
    clickLocation: `${pageType}`,
    clickName: 'update cart',
    clickText: 'remove',
    errorMessage: '',
  };

  let click: DLClickEventProps = {};
  const product: DLProductEventProps = {
    ...productDetailInfo,
    quantity: quantity,
    itemcode: itemCode,
    price: price,
  };

  switch (clickType) {
    case 'InitiateCart':
      click = clickInitiateCart;
      break;
    case 'addToCart':
      click = page === 'cart' ? addToCartClick : productAddToCartClick;
      break;
    case 'removeFromCart':
      click = clickRemoveFromCart;
      break;
    default:
      return;
  }
  adobeDataLayer.clickEvent(clickType, click, {
    product,
  });
};
