import React, { useState, useEffect, useRef, memo } from 'react';
import { css, useTheme } from '@emotion/react';
import { useRecoilValue } from 'recoil';
import { selectUserDetails, selectedStateAtom, selectUserIsUnlimited } from 'common-state';
import { ConditionalContainerEntryType } from 'tsconfig/types.d';
import { useScreenLayout } from '../../../hooks/useScreenLayout';
import USStateData from '../../../utils/USStateData';
import { isBrowser } from 'common-state/src/helpers/utils';

export const BREAKPOINTS = {
  MOBILE: 'Mobile only (small device)',
  DESKTOP: 'Desktop only (large device)',
} as const;

export type CmsConditionalContainerProps = {
  children: React.ReactNode;
  contentItem: ConditionalContainerEntryType;
};

export const CmsConditionalContainer = memo((props: CmsConditionalContainerProps) => {
  const { children, contentItem } = props;
  const userDetails = useRecoilValue(selectUserDetails);
  const selectedState = useRecoilValue(selectedStateAtom);
  const isUnlimited = useRecoilValue(selectUserIsUnlimited);
  const theme = useTheme();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [shouldRenderChildren, setShouldRenderChildren] = useState<boolean>(true);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const {
    customerLoggedIn,
    conditionSubscription,
    conditionVineyardPartner,
    conditionWineFund,
    conditionUnlimited,
    conditionBreakpoint,
    conditionWineryDirect,
    conditionState,
  } = contentItem?.fields;

  const wrapper = css`
    opacity: ${shouldRenderChildren ? 1 : 0};
    transition: opacity 2.3s ease-in-out;
  `;

  const { screen } = useScreenLayout();
  useEffect(() => {
    //conditionUnlimited
    const userUnlimited = () => {
      if (isBrowser()) {
        if (conditionUnlimited === 'Yes' && !isUnlimited) {
          return false;
        } else if (conditionUnlimited === 'No' && isUnlimited) {
          return false;
        }
        return true;
      }
    };

    //conditionSubscription
    const subscribedUser = () => {
      if (isBrowser()) {
        if (conditionSubscription === 'Yes') {
          if (userDetails?.subscriptions?.find(subscribedUserDetails => subscribedUserDetails?.status === 'ACTIVE')) {
            return true;
          }
          return false;
        }
        return true;
      }
    };

    //conditionVineyardPartner
    const vineyardPartner = () => {
      if (isBrowser()) {
        if (conditionVineyardPartner === 'Yes') {
          if (userDetails?.subscriptions?.find(subscribedUserDetails => subscribedUserDetails?.type === 'VP')) {
            return true;
          }
          return false;
        }
        return true;
      }
    };

    //conditionWineFund
    const wineFund = () => {
      if (isBrowser()) {
        if (conditionWineFund === 'Yes') {
          if (userDetails?.subscriptions?.find(subscribedUserDetails => subscribedUserDetails?.type === 'WINEFUND')) {
            return true;
          }
          return false;
        }
        return true;
      }
    };

    //User LoggedIn Check
    const userLoggedInCheck = () => {
      if (isBrowser()) {
        if (customerLoggedIn && customerLoggedIn !== 'Choose a value') {
          if (userDetails) {
            const loginType = userDetails.loginType === 'hard_logged' || userDetails.loginType === 'soft_logged';
            if (customerLoggedIn === 'Logged in' && loginType) {
              return true;
            } else if (customerLoggedIn === 'Not logged in' && !loginType) {
              return true;
            }
            return false;
          }
          return false;
        }
        return true;
      }
    };
    //Screen Breakpoint Check
    const screenBreakpointCheck = () => {
      if (typeof window !== 'undefined') {
        if (conditionBreakpoint && conditionBreakpoint !== 'Choose a value') {
          const screenIsMobileBreakpoint = window.matchMedia(`(max-width: ${theme.breakpointWidths?.lg}px)`).matches;
          if (conditionBreakpoint === BREAKPOINTS.MOBILE) {
            return screenIsMobileBreakpoint;
          }
          if (conditionBreakpoint === BREAKPOINTS.DESKTOP) {
            return !screenIsMobileBreakpoint;
          }
        } else {
          return true;
        }
      } else {
        return false;
      }
    };

    //US State Check
    const stateDataCheck = () => {
      let stateCheckValid = true;
      if (conditionState || conditionWineryDirect) {
        if (selectedState) {
          const selectedStateData = USStateData?.find(stateData => stateData?.stateCode === selectedState);
          if (!selectedStateData) {
            stateCheckValid = false;
          } else {
            if (conditionState && conditionState !== selectedStateData.stateName) {
              stateCheckValid = false;
            }
            if (conditionWineryDirect) {
              if (conditionWineryDirect === 'Yes' && selectedStateData.wineryDirect) {
                stateCheckValid = true;
              } else if (conditionWineryDirect === 'No' && !selectedStateData.wineryDirect) {
                stateCheckValid = true;
              } else {
                stateCheckValid = false;
              }
            }
          }
        } else {
          stateCheckValid = false;
        }
      }
      return stateCheckValid;
    };

    const shouldRender =
      userLoggedInCheck() &&
      screenBreakpointCheck() &&
      stateDataCheck() &&
      subscribedUser() &&
      vineyardPartner() &&
      wineFund() &&
      userUnlimited();
    setShouldRenderChildren(shouldRender as boolean);
  }, [
    screen,
    conditionBreakpoint,
    selectedState,
    conditionState,
    conditionWineryDirect,
    customerLoggedIn,
    conditionSubscription,
    conditionVineyardPartner,
    conditionWineFund,
    conditionUnlimited,
    userDetails,
  ]);

  useEffect(() => {
    const node = wrapperRef.current;
    if (node) {
      node.addEventListener('transitionend', () => {
        if (isVisible) {
          // Remove the fade-in class after the transition ends
          setIsVisible(false);
        }
      });
    }
  }, [isVisible]);

  if (shouldRenderChildren) {
    return (
      <div data-test="widget-CmsConditionalContainer" className="cms-conditional-container" css={wrapper}>
        {children}
      </div>
    );
  }

  return null;
});
