import React, { useState, useEffect } from 'react';
import { css, useTheme } from '@emotion/react';
import { TimerProps } from 'tsconfig/types';
import { getCountDownTime } from '../../../utils/helperFunction';

export const CmsTimer = ({ contentItem }: TimerProps) => {
  const { headline, endTime } = contentItem?.fields;
  const theme = useTheme();
  const [text, setText] = useState('');

  const getTime = (dt: string) => {
    setText(getCountDownTime(dt));
  };

  useEffect(() => {
    getTime(endTime);
    const interval = setInterval(() => getTime(endTime), 1000);
    return () => clearInterval(interval);
  }, []);

  const timerCss = css`
    ${theme.widgets.CmsTimer?.wrapper};
  `;

  return (
    <>
      {text !== '' && (
        <div data-test="widget-timer" css={timerCss}>
          {headline && <span className="timer-headline">{headline}</span>}
          <h2 className="timer-content">{text}</h2>
        </div>
      )}
    </>
  );
};
