import { ProductProps } from 'tsconfig/types';
import { css, useTheme } from '@emotion/react';

const medalImageMapping: { [key: string]: string } = {
  Gold: '/next/images/medals/gold_medal.svg',
  Silver: '/next/images/medals/silver_medal.svg',
  Bronze: '/next/images/medals/bronze_medal.svg',
  'Double Gold': '/next/images/medals/2_gold.svg',
  'Triple Gold': '/next/images/medals/3_gold.svg',
  'Four Gold': '/next/images/medals/4_gold.svg',
};

export const AwardDetails = (props: { product: ProductProps }) => {
  const { product } = props;
  let accolades = product.accolades;
  if (accolades.length < 1) {
    const referencedSkus = product?.skus[0]?.referencedSkus ? product.skus[0].referencedSkus : [];
    accolades = referencedSkus
      .map(item => item.expandedItem.accolades)
      .reduce((acc, accolades) => acc.concat(accolades), []);
  }

  const theme = useTheme();
  const wrapper = css`
    ${theme.widgets.Product?.AwardDetails}
  `;

  return (
    <div css={wrapper} className="award-main-container">
      {accolades.map((accolade, index) => {
        const accoladeLevelDescription = accolade?.accoladeLevelDescription;
        const accoladeTypeDescription = accolade?.accoladeTypeDescription;

        let imageUrl;
        if (accoladeTypeDescription === 'Medal') {
          imageUrl = medalImageMapping[accoladeLevelDescription];
        } else if (accoladeTypeDescription === 'Points') {
          imageUrl = '/next/images/medals/points.svg';
        } else {
          imageUrl = '/next/images/medals/trophy.svg';
        }

        return (
          accoladeTypeDescription !== 'QUOTE' && (
            <div key={index} className="medal award-text">
              {accoladeTypeDescription === 'Points'
                ? imageUrl && (
                    <div className="point-main-container">
                      <img src={imageUrl} alt={accoladeLevelDescription} />
                      <div className="point-text-container">
                        <span className="point">{accoladeLevelDescription}</span>
                        <span className="point-text">PTS</span>
                      </div>
                    </div>
                  )
                : imageUrl && <img src={imageUrl} alt={accoladeLevelDescription} />}
              <span className="tooltiptext">
                <span key={index} className="award-item">
                  {accolade.accoladeOrgDescription}, ({accolade.accoladeLevelDescription}{' '}
                  {accolade.accoladeTypeDescription})
                </span>
              </span>
            </div>
          )
        );
      })}
    </div>
  );
};
