import React, { memo, MemoExoticComponent } from 'react';
import Grid from '../../grid/Grid';
import { ColumnsEntryType, ColumnEntryType } from 'tsconfig/types.d';
import { ContainerLayout } from '../../../layouts/ContainerLayout';

type CmsColumnProps = {
  contentItem: ColumnEntryType;
  children: React.ReactNode;
};

const CmsColumn = memo((props: CmsColumnProps) => {
  const { children, contentItem } = props;
  const width = contentItem?.fields?.width || 2;
  const desktopWidth = contentItem?.fields?.desktopWidth || width;
  return (
    <Grid.Col xs={width} lg={desktopWidth}>
      {children}
    </Grid.Col>
  );
});

type CmsColumnsProps = {
  contentItem: ColumnsEntryType;
  children: React.ReactNode;
};

type ColumnsWithColumn = MemoExoticComponent<(props: CmsColumnsProps) => JSX.Element> & {
  CmsColumn: typeof CmsColumn;
};

const CmsColumnsComponent = (props: CmsColumnsProps) => {
  const { children } = props;

  return (
    <div data-test="widget-CmsColumn" className="widget-cms-column">
      <ContainerLayout>
        <Grid className="grid-container">{children}</Grid>
      </ContainerLayout>
    </div>
  );
};
const CmsColumns = memo(CmsColumnsComponent) as ColumnsWithColumn;

CmsColumns.CmsColumn = CmsColumn;

export default CmsColumns;
