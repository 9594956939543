//This is a temp placeholder for the CMS Image component
//TODO: implement this properly
import React, { memo } from 'react';
import { css, useTheme } from '@emotion/react';
import { ImageEntryType } from 'tsconfig/types.d';
import Image from 'next/image';
import { inspectorMode, useScreenLayout } from 'common-ui';

export type CmsImageProps = {
  contentItem?: ImageEntryType;
  //image defaults to filling the width of its container (while mainting aspect ratio)
  disableAutoSize?: boolean;
  isNextImg?: boolean;
  width?: number;
  height?: number;
  sizes?: string;
  fill?: boolean;
  blurDataURL?: string;
  placeholder?: 'empty' | 'blur' | `data:image/${string}` | undefined;
};

export const CmsImage = memo((props: CmsImageProps) => {
  const {
    disableAutoSize,
    contentItem,
    isNextImg,
    height,
    width,
    sizes,
    fill = false,
    blurDataURL,
    placeholder = 'empty',
  } = props;

  const theme = useTheme();

  if (!contentItem) {
    return null;
  }
  const { largeDeviceImage, smallDeviceImage, altText } = contentItem.fields;

  const imgContainStyle = css`
    max-width: 100%;
    height: auto;
  `;

  const intrinsicWidth = largeDeviceImage?.fields?.file?.details?.image?.width;
  const intrinsicHeight = largeDeviceImage?.fields?.file?.details?.image?.height;

  const mediaXs = theme.breakpoints.xs ? theme.breakpoints.xs.replace('@media', '') : '';
  const mediaMd = theme.breakpoints.md ? theme.breakpoints.md.replace('@media', '') : '';

  const conditionalImageProps = fill
    ? { fill }
    : disableAutoSize && width
      ? { width, height }
      : { width: intrinsicWidth, height: intrinsicHeight, sizes };

  const { screen, isMobile } = useScreenLayout();
  const imageSrc =
    screen && isMobile && smallDeviceImage?.fields?.file?.url
      ? smallDeviceImage?.fields?.file?.url
      : largeDeviceImage?.fields?.file?.url;

  return (
    <div data-test="widget-CmsImage" className="cms-image-container">
      {isNextImg ? (
        <Image
          src={imageSrc || ''}
          alt={altText || 'banner'}
          className="banner-img"
          css={imgContainStyle}
          blurDataURL={blurDataURL}
          placeholder={placeholder}
          data-testid="next-image"
          {...conditionalImageProps}
          {...inspectorMode(contentItem?.sys?.id, 'largeDeviceImage' || 'smallDeviceImage')}
        />
      ) : (
        <picture {...inspectorMode(contentItem?.sys?.id, 'largeDeviceImage' || 'smallDeviceImage')}>
          <source media={mediaMd} srcSet={largeDeviceImage?.fields?.file?.url} />
          <source media={mediaXs} srcSet={smallDeviceImage?.fields?.file?.url} width={'100%'} height={'100%'} />
          <img
            width={fill ? '100%' : disableAutoSize && width ? width : intrinsicWidth}
            loading="lazy"
            src={largeDeviceImage?.fields?.file?.url}
            alt={altText || 'banner'}
            className="banner-img"
            data-testid="cms-image"
          />
        </picture>
      )}
    </div>
  );
});
