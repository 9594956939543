import React, { memo } from 'react';
import { css, useTheme } from '@emotion/react';
import { createLogger } from 'common-ui';

export type IconProps = {
  kind: string;
  size?: IconSize | string;
  'data-test'?: string;
};
type IconSize = 'xs' | 's' | 'm' | 'l';

export const Icon = memo((props: IconProps) => {
  const { kind, size } = props;
  const theme = useTheme();
  const log = createLogger();
  const widgetTheme = theme.widgets?.Icon || {};

  let fontSize;
  switch (size) {
    case 'xs':
      fontSize = '15px';
      break;
    case 's':
      fontSize = '20px';
      break;
    case 'm':
      fontSize = '24px';
      break;
    case 'l':
      fontSize = '32px';
      break;
    default: //i.e. easy way to pass through custom icon size
      fontSize = size;
      break;
  }

  if (!theme.icons?.[kind]) {
    log.error(`Icon kind '${kind}' not defined`);
  }

  const iconStyle = css`
    display: inline-block;
    ${widgetTheme}
    ${theme.mixins?.iconBase}
    ${theme.icons?.[kind]}
    font-size: ${fontSize};
  `;

  return <i data-test={props['data-test'] ?? 'widget-icon'} css={iconStyle} />;
});
