import { RelatedProducts } from '@algolia/recommend-react';
import recommend from '@algolia/recommend';
import { css, useTheme } from '@emotion/react';
import { Carousel } from '@mantine/carousel';
import React, { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { selectedStateAtom } from 'common-state';
import { globals, ItemCard } from 'common-ui';

type ListViewProps = {
  items: any[];
  itemComponent: any;
  cmsCarousel: object;
  slidestoShow: number;
};

const ListView = React.memo(({ items, itemComponent, cmsCarousel, slidestoShow }: ListViewProps) => {
  const showControls = Array.isArray(items) && items.length > slidestoShow;

  return (
    <div className="container">
      {items.length > 0 ? (
        <Carousel
          withIndicators={showControls}
          withControls={showControls}
          loop={showControls}
          draggable={showControls}
          slidesToScroll={1}
          slideSize="20%"
          align="start"
          slideGap="md"
          controlsOffset="md"
          className="trending-products-carousel"
          styles={cmsCarousel}
          speed={1000}
          breakpoints={[
            { maxWidth: 'xl', slideSize: '25%' },
            { maxWidth: 'lg', slideSize: '33.33333%' },
            { maxWidth: 'md', slideSize: '50%', slideGap: 'sm' },
            { maxWidth: 'xs', slideSize: '100%', slideGap: 0 },
          ]}
        >
          {items.map(item => (
            <Carousel.Slide key={item.objectID}>{React.createElement(itemComponent, { item })}</Carousel.Slide>
          ))}
        </Carousel>
      ) : null}
    </div>
  );
});

const RelatedItem = React.memo(({ item, showRating }: { item: any; showRating: boolean }) => {
  return <ItemCard product={item} showRating={showRating ?? true} showImageZoom={true} />;
});

export const RelatedItems = ({
  currentObjectID,
  targetRef = null,
  fallbackParameters = null,
}: {
  currentObjectID: string;
  targetRef?: any;
  fallbackParameters?: any;
}) => {
  const recommendClient = recommend(
    `${process.env.NEXT_PUBLIC_INSTANT_SEARCH_APP_ID}`,
    `${process.env.NEXT_PUBLIC_INSTANT_SEARCH_SEARCH_KEY}`,
  );
  const indexName = `${process.env.NEXT_PUBLIC_INSTANT_SEARCH_INDEX_NAME}`;

  const theme = useTheme();
  const [slidestoShow, setSlidestoShow] = useState(1);

  const related = css`
    ${theme.widgets.Product?.related}
  `;

  const currentState = useRecoilValue(selectedStateAtom);

  const queryFilters =
    globals.country === 'us'
      ? `inStock:true AND (threeTierStates:${currentState} OR wineryDirectStates:${currentState})`
      : 'inStock:true';

  const fallbackFilters = fallbackParameters
    ? globals.country === 'us'
      ? `country:"${fallbackParameters.country}" AND (threeTierStates:${currentState} OR wineryDirectStates:${currentState}) AND inStock:true`
      : `country:"${fallbackParameters.country}" AND productWebType:"${fallbackParameters.productWebType}" AND inStock:true`
    : '';

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      let noOfSlides = 5;
      switch (true) {
        case windowWidth < 576:
          noOfSlides = 1;
          break;
        case windowWidth < 992:
          noOfSlides = 2;
          break;
        case windowWidth < 1200:
          noOfSlides = 3;
          break;
        case windowWidth < 1408:
          noOfSlides = 4;
          break;
        default:
          noOfSlides = 5;
      }
      setSlidestoShow(noOfSlides);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const HeaderComponent = () => {
    return <h3 className="text-center">You Might Like</h3>;
  };

  return (
    <div ref={targetRef} css={related} className="no-print">
      <RelatedProducts
        recommendClient={recommendClient}
        indexName={indexName}
        objectIDs={[currentObjectID]}
        itemComponent={props => <RelatedItem {...props} showRating={true} />}
        headerComponent={props => {
          return props?.recommendations?.length > 0 ? <HeaderComponent /> : <></>;
        }}
        view={props => {
          return <ListView {...props} cmsCarousel={theme.widgets.CmsCarousel ?? {}} slidestoShow={slidestoShow} />;
        }}
        maxRecommendations={5}
        threshold={30}
        fallbackParameters={{
          filters: fallbackFilters,
        }}
        queryParameters={{
          filters: queryFilters,
        }}
      />
    </div>
  );
};
