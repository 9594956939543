import { TrendingItems } from '@algolia/recommend-react';
import recommend from '@algolia/recommend';
import { css, useTheme } from '@emotion/react';
import { Carousel } from '@mantine/carousel';
import React, { useEffect, useMemo, useState } from 'react';
import { globals, ItemCard } from 'common-ui';

const sliderSettings = [
  { maxWidth: 'xl', slideSize: '25%' },
  { maxWidth: 'lg', slideSize: '33.33333%' },
  { maxWidth: 'md', slideSize: '50%', slideGap: 'sm' },
  { maxWidth: 'xs', slideSize: '100%', slideGap: 0 },
];

type ListViewProps = {
  items: any[];
  itemComponent: any;
  isMiniCart: boolean;
  cmsCarousel: object;
  slidestoShow: number;
};

const ListView = React.memo(({ items, itemComponent, isMiniCart, cmsCarousel, slidestoShow }: ListViewProps) => {
  const tempSliderSetting = isMiniCart ? [] : sliderSettings;
  const showControls = Array.isArray(items) && items.length > (isMiniCart ? 1 : slidestoShow);

  return (
    <>
      {items.length > 0 ? (
        <Carousel
          withIndicators={showControls}
          withControls={showControls}
          loop={showControls}
          draggable={showControls}
          slidesToScroll={1}
          slideSize={isMiniCart ? '100%' : '20%'}
          align="start"
          slideGap="md"
          controlsOffset="md"
          className="trending-products-carousel"
          styles={cmsCarousel}
          breakpoints={tempSliderSetting}
        >
          {items.map(item => (
            <Carousel.Slide key={item.objectID}>{React.createElement(itemComponent, { item })}</Carousel.Slide>
          ))}
        </Carousel>
      ) : null}
    </>
  );
});

const TrendingProductItem = React.memo(({ item, isMiniCart }: { item: any; isMiniCart: boolean }) => {
  return <ItemCard product={item} showRating={isMiniCart ? false : true} showImageZoom={isMiniCart ? false : true} />;
});

const HeaderComponent = ({ isMiniCart = false }: { isMiniCart?: boolean }) => {
  return (
    <div className="text-center">
      <h3 className="auc-Recommend-title">
        {isMiniCart ? (globals.country === 'us' ? 'May We Recommend' : 'Top Trending Wines') : 'Trending'}
      </h3>
    </div>
  );
};

export const TrendingProductItems = ({ isMiniCart = false }: { isMiniCart?: boolean }) => {
  const recommendClient = useMemo(
    () => recommend((globals?.algoliaAppId || '') as string, (globals?.algoliaSearchKey || '') as string),
    [],
  );
  const indexName = (globals?.algoliaIndexName || '') as string;

  const theme = useTheme();
  const productItemTrending = useMemo(
    () => css`
      ${theme.widgets.Cart?.productItemsTrending}
    `,
    [theme],
  );

  const [slidestoShow, setSlidesToShow] = useState(1);

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      let noOfSlides = 5;
      switch (true) {
        case windowWidth < 576:
          noOfSlides = 1;
          break;
        case windowWidth < 992:
          noOfSlides = 2;
          break;
        case windowWidth < 1200:
          noOfSlides = 3;
          break;
        case windowWidth < 1408:
          noOfSlides = 4;
          break;
        default:
          noOfSlides = 5;
      }
      setSlidesToShow(noOfSlides);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div data-testid="trending-items" css={productItemTrending} style={{ position: 'relative' }}>
      <TrendingItems
        recommendClient={recommendClient}
        indexName={indexName}
        queryParameters={{
          filters: 'inStock:true',
        }}
        itemComponent={props => <TrendingProductItem {...props} isMiniCart={isMiniCart} />}
        view={props => {
          return (
            <ListView
              {...props}
              isMiniCart={isMiniCart}
              cmsCarousel={theme.widgets.CmsCarousel ?? {}}
              slidestoShow={slidestoShow}
            />
          );
        }}
        maxRecommendations={5}
        headerComponent={props => {
          return props?.recommendations?.length > 0 ? <HeaderComponent isMiniCart={isMiniCart} /> : <></>;
        }}
      />
    </div>
  );
};
