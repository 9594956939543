// This is the main wrapper for the Direct Wines API services
// It manages which or the old or new services is called based upon supplied config
import { globals } from 'common-ui';
import { initAtgLegacyApi } from './directWinesApis/atgLegacyApi';
import { initAwsTempApi } from './directWinesApis/awsTempApi';
import { initWebApi } from './directWinesApis/webApi';
import { initWebAuthApi } from './directWinesApis/webAuthApi';
import { initBffApi } from './directWinesApis/bffApi';
import { transformAtgCartToVineCart, transformAtgProductToVineProduct, transformAwsCartToVineCart } from './transforms';
import { ProductProps } from 'tsconfig/types';

export type CommerceApiConfig = {
  //Legacy ATG
  atgApiPrefix: string; //host
  atgApiExtraHeaders?: Record<string, string>;
  //Temp AWS
  awsApiPrefix: string; //apiHost
  awsApiExtraHeaders?: Record<string, string>;
  brandToken?: string;
  userToken?: string;
  //Web/Auth API
  webApiPrefix: string; //host
  webApiHost: string; //host
  webApiExtraHeaders?: Record<string, string>;
  //misc
  enableLogging?: boolean;
};

export type CommerceApiOverrideConfig = {
  host?: string;
  //Legacy ATG
  atgApiPrefix?: string; //host
  atgApiExtraHeaders?: Record<string, string>;
  //Temp AWS
  awsApiPrefix?: string; //apiHost
  awsApiExtraHeaders?: Record<string, string>;
  brandToken?: string;
  userToken?: string;
  //Web/Auth API
  webApiPrefix?: string; //host
  webApiHost?: string; //host
  webApiExtraHeaders?: Record<string, string>;
  //misc
  enableLogging?: boolean;
};

//initialise the APi using environmental vars (via globals)
export const initCommerceApiWithGlobals = (overrideConfig: CommerceApiOverrideConfig = {}) => {
  return initCommerceApi({
    //Legacy ATG
    atgApiPrefix: globals.atgApiPrefix as string,
    //Temp AWS
    awsApiPrefix: globals.awsApiPrefix as string,
    //Web/Auth API
    webApiPrefix: globals.authAndWebApiPrefix as string,
    webApiHost: globals.authAndWebApiHost as string,
    // webApiBrandDomain: globals.brandDomain as string,
    ...overrideConfig,
  });
};

export const initCommerceApi = (config: CommerceApiConfig) => {
  const atgLegacyApi = initAtgLegacyApi({
    urlPrefix: config.atgApiPrefix,
    extraHeaders: config.atgApiExtraHeaders,
    enableLogging: config.enableLogging,
  });
  const awsTempApi = initAwsTempApi({
    urlPrefix: config.awsApiPrefix,
    extraHeaders: config.awsApiExtraHeaders,
    enableLogging: config.enableLogging,
    brandToken: config.brandToken,
    userToken: config.userToken,
  });
  const webApi = initWebApi({
    urlPrefix: config.webApiPrefix,
    extraHeaders: config.webApiExtraHeaders,
    host: config.webApiHost,
  });
  const authApi = initWebAuthApi({
    urlPrefix: config.webApiPrefix,
    extraHeaders: config.webApiExtraHeaders,
    // brandDomain: config.webApiBrandDomain
    host: config.webApiHost,
  });
  const bffApi = initBffApi({
    urlPrefix: '/next/api',
    // extraHeaders?: Record<string, string>;
  });

  return {
    Auth: {
      getAnonymousToken: authApi.Auth.getAnonymousToken,
      reload: authApi.Auth.reload,
      refresh: authApi.Auth.refresh,
    },
    User: {
      get: atgLegacyApi.User.get,
      permission: atgLegacyApi.User.permission,
      softLogout: atgLegacyApi.User.softLogout,
      hardLogout: atgLegacyApi.User.hardLogout,
      // This API is only to set user location in US Brands
      userSetLocation: atgLegacyApi.User.userSetLocation,
      getFavourites: atgLegacyApi.User.getFavourites,
      getRecentlyBrowsed: atgLegacyApi.User.getRecentlyBrowsed,
    },
    //TODO: merge Cart & CartPreferences
    Cart: {
      get: async () => {
        const atgCartResponse = await atgLegacyApi.Cart.get();
        return transformAtgCartToVineCart(atgCartResponse);
      },
      add: async (productSku: string, quantity: string) => {
        const atgCartResponse = await atgLegacyApi.Cart.add(productSku, quantity);
        return transformAtgCartToVineCart(atgCartResponse);
      },
      batch: async (data: any) => {
        const atgCartResponse = await atgLegacyApi.Cart.batch(data);
        return transformAtgCartToVineCart(atgCartResponse);
      },
      getCartOffer: atgLegacyApi.Cart.getCartOffer,
    },
    CartPreferences: {
      createNewCart: async () => {
        const webApiResponse = await webApi.CartPreferences.createNewCart();
        return transformAwsCartToVineCart(webApiResponse);
      },
      addItemToCart: async (params: any) => {
        const webApiResponse = await webApi.CartPreferences.addItemToCart(params);
        return transformAwsCartToVineCart(webApiResponse);
      },
      deleteItemFromCart: async (params: any) => {
        const webApiResponse = await webApi.CartPreferences.deleteItemFromCart(params);
        return transformAwsCartToVineCart(webApiResponse);
      },
      updateCartItem: async (params: any, data: any) => {
        const webApiResponse = await webApi.CartPreferences.updateCartItem(params, data);
        return transformAwsCartToVineCart(webApiResponse);
      },
      getMyCart: async (params: any) => {
        const webApiResponse = await webApi.CartPreferences.getMyCart(params);
        return transformAwsCartToVineCart(webApiResponse);
      },
    },
    BrandPreferences: {
      get: awsTempApi.BrandPreferences.get,
    },
    UserPreferences: {
      get: awsTempApi.UserPreferences.get,
      post: awsTempApi.UserPreferences.post,
      put: awsTempApi.UserPreferences.put,
    },
    MyUserPreferences: {
      get: awsTempApi.MyUserPreferences.get,
      post: awsTempApi.MyUserPreferences.post,
      put: awsTempApi.MyUserPreferences.put,
    },
    Product: {
      get: async (countryBrand: string, itemCode: string) => {
        const awsProductResponse = await awsTempApi.Product.get(countryBrand, itemCode);
        return transformAtgProductToVineProduct(awsProductResponse as ProductProps);
      },
      getAllProductData: bffApi.Product.getAllProductData,
      getOffers: atgLegacyApi.Product.getOffers,
      getRecentlyViewed: atgLegacyApi.Product.getRecentlyViewed,
      addItemToRecentlyViewed: atgLegacyApi.Product.addItemToRecentlyViewed,
      compliance: bffApi.Product.compliance,
    },
    Promotions: {
      getProductPromotions: atgLegacyApi.Promotions.getProductPromotions,
    },
    Subscription: {
      get: awsTempApi.Subscription.get,
      updateAutoRenew: awsTempApi.Subscription.updateAutoRenew,
      getRenewalOrdersList: awsTempApi.Subscription.getRenewalOrdersList,
      getCancelReason: awsTempApi.Subscription.getCancelReason,
      getCancelOffers: awsTempApi.Subscription.getCancelOffers,
      applyUnlimitedOffer: awsTempApi.Subscription.applyUnlimitedOffer,
    },
    WinePlan: {
      getUpcomingCaseDetails: atgLegacyApi.WinePlan.getUpcomingCaseDetails,
      getProductCaseDetails: atgLegacyApi.WinePlan.getProductCaseDetails,
      getProductItemDetails: atgLegacyApi.WinePlan.getProductItemDetails,
      getSupportedFrequencies: atgLegacyApi.WinePlan.getSupportedFrequencies,
      putSupportedFrequencies: atgLegacyApi.WinePlan.putSupportedFrequencies,
    },
  };
};
