import React, { useRef, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { GlobalHeader, GlobalFooter, createLogger } from 'common-ui';
import { initContentfulAPI } from 'common-api/contentful';
import { SiteEntryType, ContentfulApiInitConfig, HeaderFooterWrapperProps } from 'tsconfig/types';
import { useSetRecoilState } from 'recoil';
import { miniCartEnabledAtom, stickyHeaderEnabledAtom } from 'common-state';

function HeaderFooterWrapper({ config }: HeaderFooterWrapperProps) {
  const [cmsSiteConfig, setCmsSiteConfig] = useState<SiteEntryType>();
  const setMiniCartFlagState = useSetRecoilState(miniCartEnabledAtom);
  const setStickHeaderFlagState = useSetRecoilState(stickyHeaderEnabledAtom);

  const fetchCMSContent = async function (cmsConfig: ContentfulApiInitConfig) {
    const contentfulApi = initContentfulAPI(cmsConfig);
    return await contentfulApi.fetchSiteConfig();
  };

  const log = createLogger();

  useEffect(() => {
    if (config?.cmsConfig) {
      fetchCMSContent(config.cmsConfig)
        .then(res => {
          setCmsSiteConfig(res);
          setMiniCartFlagState(res?.fields?.miniCartEnabled || false);
          setStickHeaderFlagState(res?.fields?.stickyHeader || false);
        })
        .catch(() => {
          log.warn('Invalid cms config');
        });
    }
  }, [config?.cmsConfig, setMiniCartFlagState, setStickHeaderFlagState]);

  //Note: We want to embed the global header AND footer in each page but from a single app. We use createPortal
  const footerRoot = useRef(document.createElement('div'));
  document.getElementById('newFooterReactApp')?.appendChild(footerRoot.current);

  useEffect(() => {
    const navigation = document.querySelector('.stickyMainNavBar-container') as HTMLElement;
    const mainHeader = document.querySelector('#mainHeaderNavBar') as HTMLElement;
    const headerBelowSection = document.querySelector('.new-vine-section') as HTMLElement;
    const handleScroll = () => {
      if (window.scrollY >= mainHeader?.offsetHeight) {
        if (cmsSiteConfig?.fields?.stickyHeader) {
          navigation?.classList?.toggle('sticky-header', true);
          if (headerBelowSection) {
            headerBelowSection.style.marginTop = `${navigation?.offsetHeight}px`;
          }
        }
      } else {
        navigation?.classList?.toggle('sticky-header', false);
        if (headerBelowSection) {
          headerBelowSection.style.marginTop = `0px`;
        }
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [cmsSiteConfig]);

  return (
    <>
      {config?.isHeaderEnabled && (
        <GlobalHeader enableCurityLogin={config?.enableCurityLogin} cmsSiteConfig={cmsSiteConfig} />
      )}
      {config?.isFooterEnabled && createPortal(<GlobalFooter cmsSiteConfig={cmsSiteConfig} />, footerRoot.current)}
    </>
  );
}
export default HeaderFooterWrapper;
