import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { createLogger } from 'common-ui';

export function logApiRequest(
  outgoingRequest: AxiosRequestConfig = {},
  incomingResponse: AxiosResponse | undefined = {} as any,
  incomingRequestTime: Date | undefined = new Date(),
  incomingResponseTime: Date | undefined = new Date(),
  handlerName = '',
  message = '',
) {
  const log = createLogger();
  log.log({
    handler: handlerName,
    message,
    outgoingRequest: {
      method: outgoingRequest.method,
      url: outgoingRequest.url,
      //TODO: mask
      headers: outgoingRequest.headers,
      //ISO 8601 date format
      time: incomingRequestTime && incomingRequestTime.toISOString(),
    },
    incomingResponse: {
      status: incomingResponse.status,
      headers: incomingResponse.headers,
      //ISO 8601 date format
      time: incomingResponseTime && incomingResponseTime.toISOString(),
    },
  });
}
